import "normalize.css"
import "./src/styles/global.css"

/*  By default, we use npm package smooth-scroll for smooth scrolling,
    it is sometimes choppy in Firefox so a css rule is used as an alternative.  
    The first if statements selects all browsers but Firefox*/
if (!(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)){
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]', {
      speed: 300,
      easing: 'easeInQuad'
    })
  }
}